import { useMemo } from "react";
import { LeftPanel, LinkContainer } from "./SideBar.styles";
import { NavLink } from "react-router-dom";
import useSideBarITems from "./SideBarItems";
import LogoV3SVG from "../Icons/LogoV3SVG";
import Container from "@components/Container/Container";

const SideBar = () => {
  const { sideBarItems } = useSideBarITems();
  const renderSideBarLinks = useMemo(() => {
    return sideBarItems.map((item) => {
      return (
        <LinkContainer key={item.id}>
          <NavLink to={item.path}>
            {item.icon} {item.name}
          </NavLink>
        </LinkContainer>
      );
    });
  }, [sideBarItems]);

  return (
    <LeftPanel>
      <Container $padding="3.125rem 0 2.5rem 0" $justifyContent="center">
        <LogoV3SVG width="120" height="60" />
      </Container>

      {renderSideBarLinks}
    </LeftPanel>
  );
};

export default SideBar;
