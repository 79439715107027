import styled from "styled-components";
import { NAVBAR_H, SIDEBAR_W } from "@utils/constants";

export const Nav = styled.nav`
  display: flex;
  height: ${NAVBAR_H};
  background: transparent;
`;

export const RightSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${SIDEBAR_W};
  height: ${NAVBAR_H};
  margin-left: 1.25rem;
  margin-top: 1.25rem;
`;

export const LeftSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${NAVBAR_H};
  flex: 1;
`;
