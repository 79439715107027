import styled from "styled-components";
import { NAVBAR_H, SIDEBAR_W } from "@utils/constants";

export const MainContentContainer = styled.div`
  display: block;
  height: calc(100vh - ${NAVBAR_H});
  width: calc(100vw - ${SIDEBAR_W} - 1.25rem*2);
  padding: 1.25rem;
  overflow-y: scroll;
`;
