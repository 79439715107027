import React from "react";
import NavBar from "@components/NavBar/NavBar";
import SideBar from "@components/SideBar/SideBar";
import MainContent from "@components/MainContent/MainContent";
import { LayoutContainer, MainContentContainer } from "./BaseLayout.syle";

const BaseLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <LayoutContainer>
      <SideBar />
      <MainContentContainer>
        <NavBar> Left Section </NavBar>
        <MainContent>{children}</MainContent>
      </MainContentContainer>
    </LayoutContainer>
  );
};

export default BaseLayout;
