import styled from "styled-components";
import { Colors } from "@styles/vars/Colors";
import FontSizes from "@styles/vars/FontSizes";
import { IDescription } from "./Description.types";

type DescriptionWrapperType = Omit<IDescription, "children">;

export const DescriptionWrapper = styled.p<DescriptionWrapperType>`
  color: ${(props) => (props.color ? props.color : Colors.SECONDARY_FONT)};
  font-size: ${(props) => (props.size ? props.size : FontSizes.heading6)};
  ${(props) => props.$margin && `margin: ${props.$margin}`};
  ${(props) => props.weight && `font-weight: ${props.weight}`};
  ${(props) => props.align && `text-align: ${props.align}`};
  ${(props) => props.transform && `text-transform: ${props.transform}`};
  ${(props) => props.$background && `background: ${props.$background}`};
  ${(props) => props.$padding && `padding: ${props.$padding}`};
  ${(props) => props.$radius && `border-radius: ${props.$radius}`};
  


`;
