import styled from "styled-components";
import { Colors } from "@styles/vars/Colors";
import FontSizes from "@styles/vars/FontSizes";
import { ITitle } from "./Title.types";

type TitleWrapperType = Omit<ITitle, "title">;

export const TitleWrapper = styled.p<TitleWrapperType>`
  color: ${(props) => (props.color ? props.color : Colors.PRIMARY_FONT)};
  font-size: ${(props) => (props.size ? props.size : FontSizes.heading3)};
  ${(props) => props.$margin && `margin: ${props.$margin}`};
  ${(props) => props.weight && `font-weight: ${props.weight}`};
  ${(props) => props.transform && `text-transform: ${props.transform}`};
  ${(props) => props.padding && `padding: ${props.padding}`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
`;
