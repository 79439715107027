const LogoV3SVG = ({ width, height }: { width?: string; height?: string }) => {
  return (
    <svg
      width={width ?? 250}
      height={height ?? 250}
      viewBox="290 250 500 500"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      <g id="Page-11">
        <g id="Layer-1">
          <g transform="matrix(1,0,0,1,597.381,368.921)">
            <path
              d="M0,237.906C-16.673,237.906 -32.785,231.271 -44.63,219.426L-112.686,151.37C-117.397,146.658 -117.397,139.02 -112.686,134.308L-75.166,96.788C-70.454,92.077 -62.816,92.077 -58.104,96.788L-10.544,144.349C-5.487,149.405 2.832,148.979 7.345,143.432L119.209,5.949C123.414,0.781 131.013,0 136.181,4.206L177.339,37.694C182.507,41.9 183.288,49.498 179.083,54.666L48.925,214.633C37.675,228.459 21.015,236.911 3.214,237.823C2.141,237.879 1.069,237.906 0,237.906"
              style={{
                fill: "rgb(84,83,249)",
                fillRule: "nonzero",
              }}
            />
          </g>
          <g transform="matrix(1,0,0,1,417.048,368.921)">
            <path
              d="M0,237.906C-16.674,237.906 -32.786,231.269 -44.63,219.424L-112.684,151.37C-117.396,146.658 -117.396,139.02 -112.684,134.308L-75.164,96.788C-70.453,92.077 -62.814,92.077 -58.103,96.788L-10.542,144.349C-5.486,149.405 2.834,148.979 7.346,143.432L119.211,5.949C123.416,0.781 131.015,0 136.183,4.206L177.34,37.694C182.509,41.9 183.289,49.498 179.084,54.666L48.925,214.633C37.675,228.459 21.013,236.912 3.211,237.823C2.139,237.879 1.067,237.906 0,237.906"
              style={{
                fill: "rgb(45,226,208)",
                fillRule: "nonzero",
              }}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default LogoV3SVG;
