const SettingsIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="1"
        d="M14.8084 9.30241L13.4626 8.55846C13.5985 7.85685 13.5985 7.1371 13.4626 6.43549L14.8084 5.69154C14.9632 5.60686 15.0327 5.43146 14.9822 5.26815C14.6315 4.19155 14.0344 3.21776 13.2541 2.40728C13.1341 2.28329 12.9382 2.25304 12.7866 2.33772L11.4408 3.08167C10.8753 2.61595 10.2246 2.25607 9.5201 2.02018V0.535309C9.5201 0.365954 9.39689 0.217769 9.22315 0.181479C8.06376 -0.0665037 6.87594 -0.054407 5.77342 0.181479C5.59967 0.217769 5.47647 0.365954 5.47647 0.535309V2.02321C4.77515 2.26212 4.12438 2.62199 3.55574 3.08469L2.21313 2.34075C2.05834 2.25607 1.86563 2.28329 1.74559 2.4103C0.965294 3.21776 0.368227 4.19154 0.0175683 5.27118C-0.0361362 5.43448 0.0365228 5.60989 0.191318 5.69456L1.53709 6.43851C1.40125 7.14012 1.40125 7.85988 1.53709 8.56149L0.191318 9.30544C0.0365228 9.39011 -0.0329771 9.56552 0.0175683 9.72882C0.368227 10.8054 0.965294 11.7792 1.74559 12.5897C1.86563 12.7137 2.0615 12.7439 2.21313 12.6593L3.5589 11.9153C4.12438 12.381 4.77515 12.7409 5.47963 12.9768V14.4647C5.47963 14.634 5.60283 14.7822 5.77658 14.8185C6.93597 15.0665 8.12378 15.0544 9.22631 14.8185C9.40005 14.7822 9.52326 14.634 9.52326 14.4647V12.9768C10.2246 12.7379 10.8753 12.378 11.444 11.9153L12.7898 12.6593C12.9445 12.7439 13.1373 12.7167 13.2573 12.5897C14.0376 11.7822 14.6347 10.8085 14.9853 9.72882C15.0327 9.56249 14.9632 9.38709 14.8084 9.30241ZM7.49828 9.91632C6.10513 9.91632 4.97102 8.83064 4.97102 7.49698C4.97102 6.16331 6.10513 5.07763 7.49828 5.07763C8.89144 5.07763 10.0256 6.16331 10.0256 7.49698C10.0256 8.83064 8.89144 9.91632 7.49828 9.91632Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};

export default SettingsIcon;
