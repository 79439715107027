const IntegrationSVG = () => {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="1"
        d="M3.55556 2.75H12.4444V8.25H3.55556V2.75ZM14.6667 5.5C14.6667 6.2594 15.2636 6.875 16 6.875V9.625C16 10.3844 15.4031 11 14.6667 11H1.33333C0.596944 11 0 10.3844 0 9.625V6.875C0.736389 6.875 1.33333 6.2594 1.33333 5.5C1.33333 4.7406 0.736389 4.125 0 4.125V1.375C0 0.615599 0.596944 0 1.33333 0H14.6667C15.4031 0 16 0.615599 16 1.375V4.125C15.2636 4.125 14.6667 4.7406 14.6667 5.5ZM13.3333 2.52083C13.3333 2.14113 13.0349 1.83333 12.6667 1.83333H3.33333C2.96514 1.83333 2.66667 2.14113 2.66667 2.52083V8.47917C2.66667 8.85887 2.96514 9.16667 3.33333 9.16667H12.6667C13.0349 9.16667 13.3333 8.85887 13.3333 8.47917V2.52083Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};

export default IntegrationSVG;
