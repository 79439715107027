enum FontSizes {
    heading1 = '1.5rem; font-weight: 600',
    heading2 = '1.25rem; font-weight: 600',
    heading3 = '1.25rem; font-weight: 400',
    heading4 = '1.25rem; font-weight: 500',
    heading5 = '1rem; font-weight: 400',
    heading6 = '1rem; font-weight: 500',
    heading7 = '0.875rem; font-weight: 400',
    heading8 = '0.75rem; font-weight: 400',
}

export default FontSizes;



