import { DescriptionWrapper } from "./Description.styles";
import { IDescription } from "./Description.types";

const Description = (props: IDescription) => {
  const {
    children,
    size,
    $margin,
    color,
    weight,
    align,
    transform,
    $background,
    $radius,
    $padding,
  } = props;
  return (
    <DescriptionWrapper
      size={size}
      $margin={$margin}
      color={color}
      weight={weight}
      align={align}
      transform={transform}
      $background={$background}
      $radius={$radius}
      $padding={$padding}
    >
      {children}
    </DescriptionWrapper>
  );
};

export default Description;
