import { PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUser } from "@services/auth/useUser";

const PublicRoute = ({ children }: PropsWithChildren) => {
  const user = useUser();
  const { pathname } = useLocation();
  if (user?.user_id && pathname === "/auth/sign-in") {
    return <Navigate to="/overview" replace />;
  }

  return <>{children}</>;
};

export default PublicRoute;
