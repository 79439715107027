import LeftSection from "./LeftSection";
import { Nav } from "./NavBar.styles";
import RightSection from "./RigthSection";

const NavBar = ({ children }: { children: React.ReactNode }) => {
  return (
    <Nav>
      <RightSection />
      <LeftSection>{children}</LeftSection>
    </Nav>
  );
};

export default NavBar;
