import { useLocation } from "react-router-dom";
import { RightSectionWrapper } from "./NavBar.styles";
import Description from "../Description/Description";
import Title from "../Title/Title";
import FontSizes from "@styles/vars/FontSizes";

const RightSection = () => {
  const location = useLocation();

  const INITIAL_ROUTE = 'dashboard' 
  return (
    <RightSectionWrapper>
      <Description transform="capitalize" $margin="0">{INITIAL_ROUTE+location.pathname}</Description>
      <Title size={FontSizes.heading3} transform="capitalize" title={location.pathname.substring(1)} $margin="0"/>
    </RightSectionWrapper>
  );
};

export default RightSection;
