import { useTranslation } from "react-i18next";
import IntegrationSVG from "../Icons/IntegrationsSVG";
import DashboardSVG from "../Icons/DashboardSVG";
import EmployeesIcon from "../Icons/EmployeesIcon";
import SettingsIcon from "../Icons/SettingsIcon";

interface ISideBar {
  id: number;
  name: string;
  path: string;
  icon: JSX.Element;
}
const useSideBarITems = () => {
  const { t } = useTranslation();
  const SIDEBAR_ITEMS: ISideBar[] = [
    {
      id: 1,
      name: t("dashboard.overview"),
      path: "/overview",
      icon: DashboardSVG(),
    },
    {
      id: 2,
      name: t("dashboard.integrations"),
      path: "/integrations",
      icon: IntegrationSVG(),
    },
    {
      id: 3,
      name: t("dashboard.employees"),
      path: "/employees",
      icon: EmployeesIcon(),
    },
    // {
    //   id: 4,
    //   name: t("dashboard.teams"),
    //   path: "/teams",
    //   icon: EmployeesIcon(),
    // },
    // {
    //   id: 5,
    //   name: t("dashboard.subscriptions"),
    //   path: "/subscriptions",
    //   icon: SubscriptionsIcon(),
    // },
    // {
    //   id: 6,
    //   name: t("dashboard.users"),
    //   path: "/users",
    //   icon: UsersIcon(),
    // },
    {
      id: 6,
      name: t("dashboard.settings"),
      path: "/profile",
      icon: SettingsIcon(),
    },
  ];

  return { sideBarItems: SIDEBAR_ITEMS };
};

export default useSideBarITems;
