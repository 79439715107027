/* eslint-disable react-refresh/only-export-components */
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import BaseLayout from "../layout/BaseLayout";

const CompanySignInPage = React.lazy(
  () => import("@pages/auth/signin/companySignIn/CompanySignIn")
);
const EmployeeSignInPage = React.lazy(
  () => import("@pages/auth/signin/EmployeeSignIn/EmployeeSignIn")
);

const CompanySignUpPage = React.lazy(
  () => import("@pages/auth/signup/companySignUp/CompanySignUp")
);

const DashboardPage = React.lazy(() => import("@/pages/overview/OverviewPage"));

const IntegrationsPage = React.lazy(
  () => import("@pages/integrations/ItegrationsPage")
);

const LandingPage = React.lazy(() => import("@pages/landingPage/LandingPage"));

const PricingPage = React.lazy(() => import("@pages/pricingPage/PricingPage"));

const ProfilePage = React.lazy(() => import("@pages/profilePage/ProfilePage"));

// TODO: Uncomment this code when we implement the feature for individuals
// const SignInPage = React.lazy(() => import("@pages/auth/signin/SignInPage"));
// const SignUpPage = React.lazy(() => import("@pages/auth/signup/SignUpPage"));
// const EmployeeSignUpPage = React.lazy(
//   () => import("@pages/auth/signup/EmployeeSignUp/EmployeeSignUp")
// );

const router = createBrowserRouter([
  // {
  //   path: "/", // will redirect to /overview
  //   element: <ProtectedRoute />,
  // },
  {
    path: "/overview",
    element: (
      <ProtectedRoute>
        <React.Suspense fallback={"Loading..."}>
          <BaseLayout>
            <DashboardPage />
          </BaseLayout>
        </React.Suspense>
      </ProtectedRoute>
    ),
  },
  {
    path: "/integrations",
    element: (
      <ProtectedRoute>
        <React.Suspense fallback={"Loading..."}>
          <BaseLayout>
            <IntegrationsPage />
          </BaseLayout>
        </React.Suspense>
      </ProtectedRoute>
    ),
  },
  {
    path: "/employees",
    element: (
      <PublicRoute>
        <React.Suspense fallback={"Loading..."}>
          <BaseLayout>
            <h1>employees</h1>
          </BaseLayout>
        </React.Suspense>
      </PublicRoute>
    ),
  },
  {
    path: "/teams",
    element: (
      <PublicRoute>
        <React.Suspense fallback={"Loading..."}>
          <BaseLayout>
            <h1>teams</h1>
          </BaseLayout>
        </React.Suspense>
      </PublicRoute>
    ),
  },
  {
    path: "/subscriptions",
    element: (
      <PublicRoute>
        <React.Suspense fallback={"Loading..."}>
          <BaseLayout>
            <h1>subscriptions</h1>
          </BaseLayout>
        </React.Suspense>
      </PublicRoute>
    ),
  },
  {
    path: "/users",
    element: (
      <PublicRoute>
        <React.Suspense fallback={"Loading..."}>
          <BaseLayout>
            <h1>users</h1>
          </BaseLayout>
        </React.Suspense>
      </PublicRoute>
    ),
  },
  {
    path: "/settings",
    element: (
      <PublicRoute>
        <React.Suspense fallback={"Loading..."}>
          <BaseLayout>
            <h1>settings</h1>
          </BaseLayout>
        </React.Suspense>
      </PublicRoute>
    ),
  },
  {
    path: "/profile",
    element: (
      <ProtectedRoute>
        <React.Suspense fallback={"Loading..."}>
          <BaseLayout>
            <ProfilePage />
          </BaseLayout>
        </React.Suspense>
      </ProtectedRoute>
    ),
  },

  {
    path: "/auth/sign-in",
    element: (
      <PublicRoute>
        <React.Suspense fallback={"Loading..."}>
          <CompanySignInPage />
        </React.Suspense>
      </PublicRoute>
    ),
  },
  {
    path: "/auth/sign-in/employee",
    element: (
      <PublicRoute>
        <React.Suspense fallback={"Loading..."}>
          <EmployeeSignInPage />
        </React.Suspense>
      </PublicRoute>
    ),
  },
  {
    path: "/auth/sign-up",
    element: (
      <PublicRoute>
        <React.Suspense fallback={"Loading..."}>
          <CompanySignUpPage />
        </React.Suspense>
      </PublicRoute>
    ),
  },
  {
    path: "/",
    element: (
      <PublicRoute>
        <React.Suspense fallback={"Loading..."}>
          <LandingPage />
        </React.Suspense>
      </PublicRoute>
    ),
  },
  {
    path: "/pricing",
    element: (
      <PublicRoute>
        <React.Suspense fallback={"Loading..."}>
          <PricingPage />
        </React.Suspense>
      </PublicRoute>
    ),
  },

  // TODO: Uncomment this code when we implement the feature for individuals
  // {
  //   path: "/auth/sign-up",
  //   element: (
  //     <PublicRoute>
  //       <React.Suspense fallback={"Loading..."}>
  //         <SignUpPage />
  //       </React.Suspense>
  //     </PublicRoute>
  //   ),
  // },
  // {
  //   path: "/auth/sign-in",
  //   element: (
  //     <PublicRoute>
  //       <React.Suspense fallback={"Loading..."}>
  //         <SignInPage />
  //       </React.Suspense>
  //     </PublicRoute>
  //   ),
  // },
  // {
  //   path: "/auth/sign-up/employee",
  //   element: (
  //     <PublicRoute>
  //       <React.Suspense fallback={"Loading..."}>
  //         <EmployeeSignUpPage />
  //       </React.Suspense>
  //     </PublicRoute>
  //   ),
  // },
]);

export default function () {
  return <RouterProvider router={router} />;
}
