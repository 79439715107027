import { PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUser } from "@services/auth/useUser";
// import { useSignOut } from "@services/auth/useSignOut";
// import { isTokenValid } from "@utils/utils";

const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const user = useUser();
  // const signOut = useSignOut();
  const location = useLocation();

  if (location.pathname === "/") return <Navigate to="/overview" replace />;

  if (!user?.user_id) return <Navigate to="/auth/sign-in" replace />;

  // if (!isTokenValid(user?.user_id)) {
  //   signOut();
  // }
  return <>{children}</>;
};

export default ProtectedRoute;
