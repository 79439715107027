import { IUser, IUserLocalStorage } from "@/types";

const USER_LOCAL_STORAGE_KEY = 'user';

export function saveUser(user: IUserLocalStorage): void {
  localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
}

export function getUser(): IUser | undefined {
  const user = localStorage.getItem(USER_LOCAL_STORAGE_KEY);
  return user ? JSON.parse(user) : undefined;
}

export function removeUser(): void {
  localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
}
