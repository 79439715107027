import styled from "styled-components";
import { SIDEBAR_W } from "@utils/constants";
import { Colors } from "@styles/vars/Colors";
import FontSizes from "@styles/vars/FontSizes";

export const LeftPanel = styled.div`
  box-sizing: border-box;
  width: ${SIDEBAR_W};
  min-width: ${SIDEBAR_W};
  height: 100vh;
  background-color: ${Colors.PRIMARY};
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  margin-bottom: 1rem;
  & a {
    text-decoration: none;
    color: ${Colors.PRIMARY_WHITE};
    font-size: ${FontSizes.heading7};
    display: flex;
    align-items: center;
    height: 2.625rem;
    width: 100%;
    padding-left: 3rem;
    border-left: 2px solid transparent;
    & svg {
      margin-right: 0.5rem;
      width: 1.125rem;
      height: 1.125rem;
      color: ${Colors.SECONDARY_FONT};
    }
    &.active {
      border-left: 2px solid ${Colors.PRIMARY_WHITE};
      flex: 1;
      padding-left: 3rem;
      background: #d9d9d96e;
      & svg {
        color: ${Colors.PRIMARY_WHITE};
      }
    }
  }
`;
