import { TitleWrapper } from "./Title.styles";
import { ITitle } from "./Title.types";

const Title = (props: ITitle) => {
  const { title, size, $margin, color, weight, transform, padding, textAlign } =
    props;
  return (
    <TitleWrapper
      size={size}
      $margin={$margin}
      color={color}
      weight={weight}
      transform={transform}
      padding={padding}
      textAlign={textAlign}
    >
      {title}
    </TitleWrapper>
  );
};

export default Title;
