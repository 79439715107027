import { Wrapper } from "./App.styles";
import Router from "./router/Router";

function App() {
  return (
    <Wrapper>
      <Router />
    </Wrapper>
  );
}

export default App;
