const DashboardSVG = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.8125C1 1.59701 1.0856 1.39035 1.23798 1.23798C1.39035 1.0856 1.59701 1 1.8125 1H5.0625C5.27799 1 5.48465 1.0856 5.63702 1.23798C5.7894 1.39035 5.875 1.59701 5.875 1.8125V5.875C5.875 6.09049 5.7894 6.29715 5.63702 6.44952C5.48465 6.6019 5.27799 6.6875 5.0625 6.6875H1.8125C1.59701 6.6875 1.39035 6.6019 1.23798 6.44952C1.0856 6.29715 1 6.09049 1 5.875V1.8125ZM9.125 1.8125C9.125 1.59701 9.2106 1.39035 9.36298 1.23798C9.51535 1.0856 9.72201 1 9.9375 1H13.1875C13.403 1 13.6097 1.0856 13.762 1.23798C13.9144 1.39035 14 1.59701 14 1.8125V3.4375C14 3.65299 13.9144 3.85965 13.762 4.01202C13.6097 4.1644 13.403 4.25 13.1875 4.25H9.9375C9.72201 4.25 9.51535 4.1644 9.36298 4.01202C9.2106 3.85965 9.125 3.65299 9.125 3.4375V1.8125ZM1 10.75C1 10.5345 1.0856 10.3278 1.23798 10.1755C1.39035 10.0231 1.59701 9.9375 1.8125 9.9375H5.0625C5.27799 9.9375 5.48465 10.0231 5.63702 10.1755C5.7894 10.3278 5.875 10.5345 5.875 10.75V13.1875C5.875 13.403 5.7894 13.6097 5.63702 13.762C5.48465 13.9144 5.27799 14 5.0625 14H1.8125C1.59701 14 1.39035 13.9144 1.23798 13.762C1.0856 13.6097 1 13.403 1 13.1875V10.75ZM9.125 8.3125C9.125 8.09701 9.2106 7.89035 9.36298 7.73798C9.51535 7.5856 9.72201 7.5 9.9375 7.5H13.1875C13.403 7.5 13.6097 7.5856 13.762 7.73798C13.9144 7.89035 14 8.09701 14 8.3125V13.1875C14 13.403 13.9144 13.6097 13.762 13.762C13.6097 13.9144 13.403 14 13.1875 14H9.9375C9.72201 14 9.51535 13.9144 9.36298 13.762C9.2106 13.6097 9.125 13.403 9.125 13.1875V8.3125Z"
        stroke="#D9D9D9"
      />
    </svg>
  );
};

export default DashboardSVG;
