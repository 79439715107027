import { FC } from "react";
import { Wrapper } from "./Container.styles";
import { IContainer } from "./Container.types";

const Container: FC<IContainer> = (props) => {
  const {
    children,
    $gap,
    direction,
    $justifyContent,
    $alignItems,
    $overflow,
    $padding,
    $flexWrap,
    $height,
    $flex,
    $width,
    $background,
    $maxWidth,
    $border,
    $borderRadius,
  } = props;
  return (
    <Wrapper
      direction={direction}
      $gap={$gap}
      $justifyContent={$justifyContent}
      $alignItems={$alignItems}
      $overflow={$overflow}
      $padding={$padding}
      $flexWrap={$flexWrap}
      $height={$height}
      $flex={$flex}
      $width={$width}
      $background={$background}
      $maxWidth={$maxWidth}
      $border={$border}
      $borderRadius={$borderRadius}
    >
      {children}
    </Wrapper>
  );
};

export default Container;
