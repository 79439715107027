/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum Colors {
  PRIMARY = "#030075",
  SECONDARY = "#5453f9",
  TERTIARY = "#2de2d0",

  PRIMARY_FONT = "#23262F",
  SECONDARY_FONT = "#5A5858",
  TERTIARY_FONT = "#A1A1A1",
  LANDING_PAGE_TITLE = "#33295D",

  BORDER = "#A1A1A1",

  PRIMARY_BACKGROUND = "#F4F7FE",
  PRIMARY_WHITE = "#FFF",
  PRIMARY_COLOR = "#4318FF",
  GRAY_SEPARATOR = "#DBDBDB",
  INPUT_STROKE = "#E0E2E7",
  INPUT_FOCUS_STROKE = "#2E65F3",
  RED_WARNING = "#FC3A35",
  BLACK_OVERLAY = "#00000050",

  SALMON = "#F6866A",
  CRAYOLA = "#59E6F6",
  SKY_BLUE = "#65AEFF",
  SLATE_BLUE = "#7661E2",
  MELLOW_APRICOT = "#FABE7A",
  TURQUOISE = "#50E2D5",

  BRIGHT_GRAY = "#E6E8EC",
}

export enum Radius {
  PRIMARY = "0.375rem",
}

export enum Distances {
  PADDING = "0.75rem",
}

export enum Shadows {
  BOX_SHADOW = "0 1rem 1.5rem 0 rgba(44, 44, 44, 0.1)",
  BOX_SHADOW_DARK = "0 1px 0.25rem 0 rgba(31, 31, 31, 0.5)",
  STUDIO_UI_SHADOW = "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
}
