import styled from "styled-components";
import { IContainer } from "./Container.types";
import { Colors } from "@styles/vars/Colors";

type ContainerType = Omit<IContainer, "children">;

export const Wrapper = styled.div<ContainerType>`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  gap: ${(props) => (props.$gap ? props.$gap : "0")};
  color: ${Colors.PRIMARY_FONT};
  ${(props) =>
    props.$justifyContent && `justify-content: ${props.$justifyContent}`};
  ${(props) => props.$alignItems && `align-items: ${props.$alignItems}`};
  ${(props) => props.$overflow && `overflow: ${props.$overflow}`};
  ${(props) => props.$padding && `padding: ${props.$padding}`};
  ${(props) => props.$height && `height: ${props.$height}`};
  ${(props) => props.$flexWrap && `flex-wrap: ${props.$flexWrap}`};
  ${(props) => props.$flex && `flex: ${props.$flex}`};
  ${(props) => props.$width && `width: ${props.$width}`};
  ${(props) => props.$background && `background: ${props.$background}`};
  ${(props) => props.$maxWidth && `max-width: ${props.$maxWidth}`};
  ${(props) => props.$border && `border: ${props.$border}`};
  ${(props) => props.$borderRadius && `border-radius: ${props.$borderRadius}`};

`;
