import styled from "styled-components";

export const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
`;

export const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
